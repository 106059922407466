<template>
	<div class="text-center m-5">
    <h3 class = "text-center">Your session has expired!</h3>
    <p class="text-center">For security reasons, your session has expired and you have been automatically signed out.</p>
    <p class="text-center">Please <a class = "actionprimary" href="/">sign in</a> again to continue.</p>
  </div>
</template>	
<script type="text/javascript"></script>
<script>
  export default{
    
    data(){
      return{

      }
    },
    computed: {},
    filters: {},
    mounted() {

      $('#preloader').addClass('hide');
      localStorage.idlein = ""
      localStorage.remember = ""
      localStorage.token = ""
      location.href="/"

      localStorage.setItem('message',JSON.stringify( {'command':'signoutall'} ));
      localStorage.removeItem('message');
      
    },
    methods: {
    }
  }
</script>